<template>
	<div class="commonHeight">
		<div class="flex header">
			<h2>停车少于半小时车辆数据分析</h2>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="100px">
			<el-form-item label="停车场" class="mb-1">
				<el-select v-model="searchForm.parkId" placeholder="请选择停车场">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
										 :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<div class="flex">
				<el-form-item label="统计时间段">
					<el-date-picker
							v-model="searchForm.beginDate"
							type="date"
							placeholder="开始时间">
					</el-date-picker>
					--
					<el-date-picker
							v-model="searchForm.endDate"
							type="date"
							placeholder="结束时间">
					</el-date-picker>
				</el-form-item>
				<div class="averageWid btnGroup">
					<el-button @click="searchData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<!--		当天-->
		<div class="leftFloat" v-if="showDay">
			<h3 class="tit">停车少于半小时车辆</h3>
			<div class="flex frequency">
				<div class="text"><span>{{ inPark }}</span><strong>车辆数值</strong></div>
				<echartscom id="lessPark" v-if="entryCarOneHour.chartData.length > 0" :nameData="entryCarOneHour.rows"
										:chartData="entryCarOneHour.chartData"></echartscom>
			</div>
		</div>
		<!--		时间段-->
		<div class="exceedTab " v-else>
			<h3 class="tit">停车少于半小时车辆</h3>
			<div class="flex">
				<div class="echarts" style="width: 70%;">
					<histogram id="lessParkLine" type="line" :data="parkRate" v-if="parkRate.xValue.length > 0"></histogram>
				</div>
				<div style="width: 30%;">
					<echartscom id="olessParkTime" v-if="entryCarTimes.chartData.length > 0" :nameData="entryCarTimes.rows"
											:chartData="entryCarTimes.chartData"></echartscom>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {
	getCarParkInfoList,
	getSentryCarStatistics,
	getDurationAndTotalLoss,
	getLessThanHalfHourByTime
} from "@/api/api";
import moment from "moment";

export default {
	name: "lessThan",
	data() {
		return {
			showDay: true,
			searchForm: {
				parkId: "",
				beginDate: "",
				endDate: ""
			},
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			parkingList: [],
			inPark: 0,
			entryCarOneHour: {
				rows: [],
				chartData: []
			},
			parkRate: {
				Yunit: "",
				xValue: [],//"2020/12/13", "2020/12/14", "2020/12/15", "2020/12/16", "2020/12/17"
				colors: ["#19D4AE"],
				listscore: [
					{ "name": "停车少于半小时车辆", "yValue": [] } //[100, 80, 65, 40, 50]
				]
			},
			entryCarTimes: {
				rows: [],
				chartData: []
			},
			dateList: []
		};
	},
	mounted() {
		this.getParkingList();
		this.getLessThanHalfHourByCurrentTime();
	},
	methods: {
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},

		//当前时间停车少于半小时的统计
		getLessThanHalfHourByCurrentTime() {
			let param = {
				entryTime: moment().subtract(1, "days").format("YYYY-MM-DD"),
				isHalf: true,
				parkId: this.searchForm.parkId
			};
			getSentryCarStatistics(JSON.stringify(param)).then(res => {
				this.inPark = res.data.data.count;
				let arr = [], arr2 = [];
				let list = res.data.data.list;
				list.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				this.entryCarOneHour.rows = arr;
				this.entryCarOneHour.chartData = arr2;

			});
		},

		//某个时间段内停车少于半小时的统计
		getLessThanHalfHourByTimes() {
			let param = {
				isHalf: true,
				parkId: this.searchForm.parkId
			};
			if (this.searchForm.beginDate != "" && this.searchForm.endDate != "") {
				param.entryTime = moment(this.searchForm.beginDate).format("YYYY-MM-DD");
				param.outTime = moment(this.searchForm.endDate).format("YYYY-MM-DD");
			}
			getDurationAndTotalLoss(JSON.stringify(param)).then(res => {
				this.parkRate.xValue = this.dateList;
				let data = res.data.data.list;
				let map = new Map();
				data.forEach(i => {
					map.set(i.outTimeDay, i.outNum);
				});
				this.dateList.forEach(o => {
					if (map.has(o)) {
						this.parkRate.listscore[0].yValue.push(map.get(o));
					} else {
						this.parkRate.listscore[0].yValue.push(0);
					}
				});
				//let list = res.data.data.list
				//this.parkRate.xValue = list.map(i=>i.outTimeDay)
				//this.parkRate.listscore[0].yValue = list.map(i=>i.outNum)
			});
			getLessThanHalfHourByTime(JSON.stringify(param)).then(res => {

				let arr = [], arr2 = [];
				let list = res.data.data;
				list.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});

				this.entryCarTimes.rows = arr;
				this.entryCarTimes.chartData = arr2;

			});
		},
		initData() {
			this.entryCarOneHour = {
				rows: [],
				chartData: []
			};
			this.parkRate.xValue = [];
			this.parkRate.listscore[0].yValue = [];

		},
		searchData() {
			this.entryCarOneHour = {
				rows: [],
				chartData: []
			};
			this.parkRate.xValue = [];
			this.parkRate.listscore[0].yValue = [];
			let day = moment(this.searchForm.beginDate);
			let day2 = moment(this.searchForm.endDate);
			let same = day.isSame(day2, "day");
			if (same == true || day == "" || day2 == "") {
				this.showDay = true;
				this.getLessThanHalfHourByCurrentTime();
			} else {
				this.showDay = false;
				this.getDateByDiffTimes();
				this.getLessThanHalfHourByTimes();
			}
		},
		reloadSearch() {
			this.searchForm = {
				parkId: "",
				beginDate: "",
				endDate: ""
			};
			this.showDay = true;
			this.getLessThanHalfHourByCurrentTime();
		},
		getDateByDiffTimes() {
			let begin = moment(this.searchForm.beginDate).format("YYYY-MM-DD");
			let end = moment(this.searchForm.endDate).format("YYYY-MM-DD");
			let diff = moment(end).diff(begin, "days");
			for (let i = 0; i <= diff; i++) {
				this.dateList.push(begin);
				begin = moment(begin).add(1, "days").format("YYYY-MM-DD");
			}
		}
	},
	components: {
		"echartscom": () => import("../account/echartscom"),
		"histogram": () => import("./histogram")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/overNight";

.leftFloat {
	width: 60%;
}
</style>
